<template>
  <v-dialog
    v-model="dialogAddDiapositiva.estatus"
    max-width="600"
    persistent
  >
    <v-card class="shadowCard">
		  <v-card-title primary-title>
		    <span class="text-subtitle-1">Administración de diapositivas</span>
		    <v-spacer></v-spacer>

        <v-btn 
          class="mr-2" 
          small dark color="green"
          @click="exportExcel( diapositivas  , 'escuelas_ERP')"
          tile
        >
          <v-icon small>mdi-microsoft-excel</v-icon>
        </v-btn>

        <v-btn
          color="orange"
          dark
          class="mr-2"
          @click="dialog = true"
          small
          tile
        >
          <v-icon small>mdi-plus</v-icon>
        </v-btn>

        <v-btn
          color="red"
          dark
          class="mr-2"
          @click="dialogMultiple = true"
          small
          tile
        >
          <v-icon small>mdi-folder-multiple-plus</v-icon>
        </v-btn>

        <v-btn
          color="primary"
          dark
          class="text-capitalize"
          @click="initialize()"
          small
          tile
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        
		  </v-card-title>

		  <v-card-text>
		  	<v-row justify="end">
		  		<v-col cols="12" md="10">
		  			<v-text-field
		  			  name="name"
		  			  label="Buscar"
		  			  id="id"
		  			  filled
		  			  dense
		  			  single-line
		  			  hide-details
		  			  append-icon="mdi-magnify"
		  			  v-model="search"
		  			>
		  			</v-text-field>
		  		</v-col>
		  	</v-row>
		    <v-row>
		    	<v-col cols="12">
					  <v-data-table
					    :headers="headers"
					    :items="diapositivas"
					    class="elevation-0"
					    :search="search"
					    :mobile-breakpoint="100"
	            dense
					  >

					  	<template v-slot:item.portada="{ item }">
					    	<v-img :src="url_servidor + item.urlPortada " aspect-ratio="2" contain/>
					    </template>

					    <template v-slot:item.actions="{ item }">
					    	<v-btn 
					    		color="primary" 
					    		x-small
					    		@click="editItem(item)"
					    		class="mr-2"
					    	>
						      <v-icon small>mdi-magnify</v-icon>
					    	</v-btn>

					    	<v-btn 
					    		color="error" 
					    		x-small
					    		@click="deleteItem(item)"
					    		class="mr-2"
					    	>
						      <v-icon small> mdi-delete</v-icon>
					    	</v-btn>

							<v-btn 
					    		color="blue" 
					    		x-small
					    		@click="infoDiapositivas = item, subirFila()"
					    		class="mr-2"
					    		dark
					    	>
						      <v-icon small> mdi-arrow-collapse-up</v-icon>
					    	</v-btn>

							<v-btn 
					    		color="blue" 
					    		x-small
					    		@click="infoDiapositivas = item, bajarFila()"
					    		class="mr-2"
					    		dark
					    	>
						      <v-icon small> mdi-arrow-collapse-down</v-icon>
					    	</v-btn>
					    </template>

					    <template v-slot:no-data>
					      <v-btn
					        color="primary"
					        @click="initialize"
					        small
					      >
					        Actualizar
					      </v-btn>
					    </template>

					  </v-data-table>
		    	</v-col>
		    </v-row>
		  </v-card-text>
		  <v-card-actions>
		  	<v-btn 
		  		color="black"
		  		dark
		  		small
		  		tile
		  		@click="$emit('initialize2')"
		  	>
		  		<v-icon small left>mdi-close</v-icon>
		  		Cerrar
		  	</v-btn>
        <v-spacer></v-spacer>
        <v-pagination
          circle
          v-model="page"
          :length="pageCount"
          :total-visible="7"
        ></v-pagination>
      </v-card-actions>
		</v-card>

    <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.iddiapositivas }}</strong>
          </span>
        </v-card-title>

        <v-card-text @keyup.enter="save">
          <v-col cols="12">
          	<label>Portada o fondo</label>
	          <div v-if="!file">
	            <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
	              <div class="dropZone-info" @drag="onChange">
	                <span class="fa fa-cloud-upload dropZone-title"></span>
	                <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
	                <div class="dropZone-upload-limit-info">
	                  <div>Extensión: png, jpg, jpeg, svg</div>
	                  <div>Tamaño máximo: 10 MB</div>
	                </div>
	              </div>
	              <input type="file" @change="onChange">
	            </div>
	          </div>

	          <!-- Botón para eliminar la imagen -->
	          <v-btn  v-else color="red" class="mb-2" dense @click="file = null, vistaPrevia = null, extension = ''" fab small dark><v-icon>mdi-delete</v-icon></v-btn>
	          
	          <!-- Mostrar la imagen -->
	          <v-img :src="vistaPrevia.url" v-if="vistaPrevia && ['jpg', 'png', 'jpeg', 'JPG'].includes( vistaPrevia.extension )" contain max-height="300"/>


	      		<label>Video o audio: </label>
	          <div v-if="!file2">
	            <div :class="['dropZone', dragging2 ? 'dropZone-over' : '']" @dragenter="dragging2 = true" @dragleave="dragging2 = false">
	              <div class="dropZone-info" @drag="onChange2">
	                <span class="fa fa-cloud-upload dropZone-title"></span>
	                <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
	                <div class="dropZone-upload-limit-info">
	                  <div>Extensión: png, jpg, jpeg, svg</div>
	                  <div>Tamaño máximo: 10 MB</div>
	                </div>
	              </div>
	              <input type="file" @change="onChange2">
	            </div>
	          </div>
	          <!-- Botón para eliminar la imagen -->
	          <v-btn  v-else color="red" class="mb-2" dense @click="file2 = null, vistaPrevia2 = null, extension2 = ''" fab small dark><v-icon>mdi-delete</v-icon></v-btn>
	          
	          <!-- Mostrar la imagen -->
	          <v-img :src="vistaPrevia2.url" v-if="vistaPrevia2 && ['jpg', 'png', 'jpeg', 'JPG'].includes( vistaPrevia2.extension )" contain max-height="300"/>

	          <v-img :src="vistaPrevia2.url" v-if="vistaPrevia2 && ['jpg', 'png', 'jpeg', 'JPG'].includes( vistaPrevia2.extension )" contain max-height="300"/>
	          <v-card
	          	v-if="vistaPrevia2 && ['mp4'].includes( vistaPrevia2.extension )" 
              style="width: 100%; height: auto"
            >
              <video controls style="width: 100%; height: 100%">
                <source
                  :src="vistaPrevia2.url"
                  type="video/mp4"
                />
              </video>
            </v-card>


	          <audio controls v-if="vistaPrevia2 && ['mp3'].includes( vistaPrevia2.extension )">
						  <source :src="vistaPrevia2.url" type="audio/mpeg">
						  Tu navegador no soporta el elemento de audio.
						</audio>

	          <!-- Botón paradecir se se reproduce automáticamente o no -->
	          <v-checkbox label="Reproducción Automática" v-model="automatica" :value="1"></v-checkbox>

      		</v-col>

        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            dark
            small
            @click="dialog = false"
            tile
            dense
          >
          	<v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            dark
            @click="save"
            tile
          >
          	<v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialogMultiple"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.iddiapositivas }}</strong>
          </span>
        </v-card-title>


        <v-row>
        	<v-col cols="12">
            <v-file-input small-chips multiple filled v-model="filesMultiples" accept="image/png, image/jpeg, image/bmp, video/mp4, video/x-m4v, video/*"
              placeholder="Selecciona tus imagenes" prepend-icon="mdi-camera" label="Imágenes" @change="cargarFotosMultiple()">
            </v-file-input>
          </v-col>
        </v-row>

        <v-row v-if="vistaPreviasMultiples.length">
          <v-col cols="12" md="3" v-for="(img, i) in vistaPreviasMultiples" :key="i">
            <v-card class="py-4 shadowCard">
              <v-img v-if="!img.video" :src="img.url" contain aspect-ratio="2"></v-img>
              <video v-else :src="img.url" contain aspect-ratio="2" style="width: 100%; height: 100%;" class="d-flex"></video>
              <v-btn  color="error"  small  @click="eliminarFoto(img.url)" top right absolute fab >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>

        <v-card-text @keyup.enter="save">
          <v-col cols="12">
          	<label>Portada o fondo</label>
	          <div v-if="!file">
	            <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
	              <div class="dropZone-info" @drag="onChange">
	                <span class="fa fa-cloud-upload dropZone-title"></span>
	                <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
	                <div class="dropZone-upload-limit-info">
	                  <div>Extensión: png, jpg, jpeg, svg</div>
	                  <div>Tamaño máximo: 10 MB</div>
	                </div>
	              </div>
	              <input type="file" @change="onChange">
	            </div>
	          </div>

	          <!-- Botón para eliminar la imagen -->
	          <v-btn  v-else color="red" class="mb-2" dense @click="file = null, vistaPrevia = null, extension = ''" fab small dark><v-icon>mdi-delete</v-icon></v-btn>
	          
	          <!-- Mostrar la imagen -->
	          <v-img :src="vistaPrevia.url" v-if="vistaPrevia && ['jpg', 'png', 'jpeg', 'JPG'].includes( vistaPrevia.extension )" contain max-height="300"/>


	      		<label>Video o audio: </label>
	          <div v-if="!file2">
	            <div :class="['dropZone', dragging2 ? 'dropZone-over' : '']" @dragenter="dragging2 = true" @dragleave="dragging2 = false">
	              <div class="dropZone-info" @drag="onChange2">
	                <span class="fa fa-cloud-upload dropZone-title"></span>
	                <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
	                <div class="dropZone-upload-limit-info">
	                  <div>Extensión: png, jpg, jpeg, svg</div>
	                  <div>Tamaño máximo: 10 MB</div>
	                </div>
	              </div>
	              <input type="file" @change="onChange2">
	            </div>
	          </div>
	          <!-- Botón para eliminar la imagen -->
	          <v-btn  v-else color="red" class="mb-2" dense @click="file2 = null, vistaPrevia2 = null, extension2 = ''" fab small dark><v-icon>mdi-delete</v-icon></v-btn>
	          
	          <!-- Mostrar la imagen -->
	          <v-img :src="vistaPrevia2.url" v-if="vistaPrevia2 && ['jpg', 'png', 'jpeg', 'JPG'].includes( vistaPrevia2.extension )" contain max-height="300"/>

	          <v-img :src="vistaPrevia2.url" v-if="vistaPrevia2 && ['jpg', 'png', 'jpeg', 'JPG'].includes( vistaPrevia2.extension )" contain max-height="300"/>
	          <v-card
	          	v-if="vistaPrevia2 && ['mp4'].includes( vistaPrevia2.extension )" 
              style="width: 100%; height: auto"
            >
              <video controls style="width: 100%; height: 100%">
                <source
                  :src="vistaPrevia2.url"
                  type="video/mp4"
                />
              </video>
            </v-card>


	          <audio controls v-if="vistaPrevia2 && ['mp3'].includes( vistaPrevia2.extension )">
						  <source :src="vistaPrevia2.url" type="audio/mpeg">
						  Tu navegador no soporta el elemento de audio.
						</audio>

	          <!-- Botón paradecir se se reproduce automáticamente o no -->
	          <v-checkbox label="Reproducción Automática" v-model="automatica" :value="1"></v-checkbox>

      		</v-col>

        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            dark
            small
            @click="dialogMultiple = false"
            tile
            dense
          >
          	<v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            dark
            @click="save"
            tile
          >
          	<v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar los datos?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {

  mixins: [ validarErrores, funcionesExcel ],

  props: ['dialogAddDiapositiva','escuela', 'nivel','leccion'],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,

	  infoDiapositivas: [],
      
      editedIndex: -1,

      editedItem: {
        iddiapositivas:0,
				nivel:'',
        idescuelas:0,
        portada_niveles:'',
        portada_lecciones:'',
				deleted:0,
				fecha_creacion: null,
      },

      defaultItem: {
        iddiapositivas:0,
				nivel:'',
        idescuelas:0,
        portada_niveles:'',
        portada_lecciones:'',
				deleted:0,
				fecha_creacion: null,
      },

			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      diapositivas: [],
      headers: [
        { text: 'ID'         , value: 'iddiapositivas'  },
        { text: 'Portada'    , value: 'portada'         },
        { text: 'Lección'    , value: 'idarchivos'      },
        { text: 'Actions'    , value: 'actions', sortable: false },
      ],

      pdf: null,
			dialogPDF: false,
	    
	    archivos:null,
	    vistaPrevia: null,
	    file: '',
	    file2: '',
			vistaPrevia2: null,
			extension2:'',
	    dragging: false,
	    dragging2: false,
	    dialgImagen:false,
			url_servidor: '',
			fullscreen: false,
			pdf:null,
			dialogPDF: false,
			automatica: 0,
			imagen: null,
			extension:'',

			dialogMultiple: false,
			filesMultiples:[],
			vistaPreviasMultiples: [],
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Diapositiva' : 'Editar Diapositiva'
      },
    },

    watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
    	// this.url_servidor = axios.defaults.baseURL + "galeria/";
		this.url_servidor = 'https://academicokpi.club/kpi/galeria/'
		console.log('url_servidor', this.url_servidor)
    	console.log( this.leccion )
      await this.initialize()
    },

    methods: {
    //   initialize () {
    //   	this.cargar = true
    //     this.diapositivas = []
    //     return this.$http.get('diapositivas.get/' + this.leccion ).then(response=>{
    //     	this.diapositivas = response.data.diapositivas
    //     	this.cargar      = false
    //     }).catch( error =>{
    //       this.validarError( error.response.data.message )
    //     }).finally( () => { this.cargar = false })
    //   },


	  initialize() {
        this.cargar = true;
        this.diapositivas = []

        return axios.get('https://academicokpi.club/kpi/diapositivas.get/' + this.leccion)
          .then(response => {
            this.diapositivas = response.data.diapositivas
            this.cargar = false;
          })
          .catch(error => {
            this.validarError(error.response.data.message);
          })
          .finally(() => {
            this.cargar = false;
          });
      },


      editItem (item) {
        this.editedIndex = this.diapositivas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.diapositivas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem.deleted = 1
        this.$http.put('diapositiva.update/' + this.editedItem.iddiapositivas, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
        this.dialog = true
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {

	    	// PORTADA
	    	let portada = this.file ? await this.grabarImagen( ) : 0

      	// CONTENIDO
      	// Mandamos a llamar grabarImagen2
	    	let idarchivos = this.file2 ? await this.grabarImagen2( ) : 0


	    	this.editedItem.portada     = portada // Guardamos el nombre del archivo en el campo de portada
	    	this.editedItem.idarchivos  = idarchivos // Guardamos el id del archivo guardado

	    	this.editedItem.idlecciones = this.leccion // Guardamos a qué lección le pertenece

      	// Cargamos al usuario que hizo el cambio
	      this.cargar = true

        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('diapositiva.update/' + this.editedItem.iddiapositivas, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
        		this.close()
          	this.initialize()
	        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        } else {
	        // Lo mandapos por el EP
          this.$http.post('diapositiva.add', this.editedItem).then(response=>{
          	this.initialize()
        		this.close()
	        	this.cargar = false
				console.log('Se agrego la diapositiva')
	        }).catch( error =>{
				console.log('Valio Chorizo')
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
      },

      getBase64(file) {
	      var me = this
	      var reader = new FileReader();
	      reader.readAsDataURL(file);
	      reader.onload = function () {

	      	let extension = file.name.split('.')

	        me.vistaPrevia = {
	          url: reader.result, 
	          image_name: file.name,
	          extension: extension[extension.length - 1]
	        }
	      };
	    },

	    getBase642(file) {
	      var me = this
	      var reader = new FileReader();
	      reader.readAsDataURL(file);
	      reader.onload = function () {

	      	let extension = file.name.split('.')

	        me.vistaPrevia2 = {
	          url: reader.result, 
	          image_name: file.name,
	          extension: extension[extension.length - 1]
	        }
	      };
	    },
	 
			onChange(e) {
	      var files = e.target.files || e.dataTransfer.files;

	      if (!files.length) {
	        this.dragging = false;
	        return;
	      }
	      
	      this.createFile(files[0]);
	    },

	    onChange2(e) {
	      var files = e.target.files || e.dataTransfer.files;

	      if (!files.length) {
	        this.dragging = false;
	        return;
	      }
	      
	      this.createFile2(files[0]);
	    },

	    createFile(file) {
	      if (file.size > 100000000000) {
	        alert('please check file size no over 40 MB.')
	        this.dragging = false;
	        return;
	      }
	      
	      let extension = file.name.split('.')
	      
	      this.file = file;
	      this.extension = extension[extension.length - 1]
	      this.getBase64(this.file)
	      this.dragging = false;
	    },

	    createFile2(file) {
	      if (file.size > 100000000000) {
	        alert('please check file size no over 40 MB.')
	        this.dragging2 = false;
	        return;
	      }
	      
	      let extension = file.name.split('.')
	      
	      this.file2 = file;
	      this.extension2 = extension[extension.length - 1]
	      this.getBase642(this.file2)
	      this.dragging2 = false;
	    },

	    // GRABAR LA PORTADA
	    // grabarImagen( ){
	    //   return new Promise((resolve,reject)=>{
	    //     let formData = new FormData();
	    //     formData.append("file"             , this.file);
	    //     formData.append("idescuelas"       , this.escuela.idescuelas);
	    //     formData.append("url"              , "");
	    //     formData.append("tipo_reproduccion", 0);
	    //     formData.append("interno"          , 1);
	    //     formData.append("descripcion"      , "Diapositiva");

	    //     this.$http.post("archivo.add", formData).then( response => {
	    //     	this.vistaPrevia  = null
	    //     	this.file         = null
	    //       resolve( response.data.id ) 
	    //     }).catch( error =>{
	    //       reject( error )
	    //     }).finally( () => { this.cargar = false })
	    //   })
	    // },

		// GRABAR EL CONTENIDO EN EL SERVIDOR
	    // grabarImagen2( ){
	    //   return new Promise((resolve,reject)=>{
	    //     let formData = new FormData();
	    //     formData.append("file"             , this.file2);
	    //     formData.append("idescuelas"       , this.escuela.idescuelas);
	    //     formData.append("url"              , "");
	    //     formData.append("tipo_reproduccion", this.automatica); 
	    //     formData.append("interno"          , 1);
	    //     formData.append("descripcion"      , "Diapositiva");

	    //     this.$http.post("archivo.add", formData).then( response => {
	    //     	this.vistaPrevia2 = null
	    //     	this.file2        = null
	    //       resolve( response.data.id ) 
	    //     }).catch( error =>{
	    //       reject( error )
	    //     }).finally( () => { this.cargar = false })
	    //   })
	    // },

		grabarImagen() {

		let formData = new FormData();
		formData.append("file", this.file);
		formData.append("idescuelas", this.escuela.idescuelas);
		formData.append("url", "");
		formData.append("tipo_reproduccion", 0);
		formData.append("interno", 1);
		formData.append("descripcion", "Diapositiva");

		return axios.post("https://academicokpi.club/kpi/archivo.add", formData)
			.then((response) => {
				this.vistaPrevia = null;
				this.file = null;
				return Promise.resolve(response.data.id);
			})
			.catch((error) => {
				return Promise.reject(error);
			})
			.finally(() => {
				this.cargar = false;
			});
		},

		grabarImagen2() {
		let formData = new FormData();
		formData.append("file", this.file2);
		formData.append("idescuelas", this.escuela.idescuelas);
		formData.append("url", "");
		formData.append("tipo_reproduccion", 0);
		formData.append("interno", 1);
		formData.append("descripcion", "Diapositiva");

		return axios.post("https://academicokpi.club/kpi/archivo.add", formData)
			.then((response) => {
				this.vistaPrevia2 = null;
				this.file2 = null;
				return Promise.resolve(response.data.id);
			})
			.catch((error) => {
				return Promise.reject(error);
			})
			.finally(() => {
				this.cargar = false;
			});
		},
	    
		subirFila ( ){
			this.cargar = true

			const payload = {
				iddiapositivas: this.infoDiapositivas.iddiapositivas,
				idarchivos: this.infoDiapositivas.idarchivos,
				idlecciones: this.infoDiapositivas.idlecciones,
				portada: this.infoDiapositivas.portada,
				orden: this.infoDiapositivas.orden
			};

			return this.$http.post('diapositiva.subirdiapositiva', payload ).then(response=>{
				// this.diapositivas = response.data.diapositivas
				this.initialize()
				this.cargar           = false
			}).catch( error =>{
				this.validarError( error.response.data.message )
			}).finally( () => { this.cargar = false })
		
		},

		bajarFila ( ){
			this.cargar = true

			const payload = {
				iddiapositivas: this.infoDiapositivas.iddiapositivas,
				idarchivos: this.infoDiapositivas.idarchivos,
				idlecciones: this.infoDiapositivas.idlecciones,
				portada: this.infoDiapositivas.portada,
				orden: this.infoDiapositivas.orden
			};

			return this.$http.post('diapositiva.bajardiapositiva', payload ).then(response=>{
				// this.diapositivas = response.data.diapositivas
				this.initialize()
				this.cargar           = false
			}).catch( error =>{
				this.validarError( error.response.data.message )
			}).finally( () => { this.cargar = false })
		
		},

			cargarFotosMultiple(){
	      if(this.filesMultiples.length < 1){
	        return
	      }

	      this.filesMultiples.forEach((element, index) => {
          // creamos una variable tipo FormData
	        let formData = new FormData();
          //se crea el objeto y se le agrega como un apendice el archivo 
	        formData.append('file', element);
          //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
          if(element.size > 26214400){ //26214400 = 25MB
            this.validarErrorDirecto('El archivo sobrepasa el limite del peso, max 25 MB')
          } else {
            this.getBase64(element, formData)
          }
        })
	    },

    },
  }
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>